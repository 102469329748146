function IsIE() {
    if (navigator.appName == "Netscape") {
        /// in IE 11 the navigator.appVersion says 'trident'
        /// in Edge the navigator.appVersion does not say trident
        if (navigator.appVersion.indexOf('Trident') === -1) {
        } else {
            window.location.href = "/NotCompatible/Index";
        }
    } 
}


/**
* Fonction permettant d'afficher une popup de confirmation
* @param text   The text to display (question)
* @param cancelButtonText   The text of cancel button
* @param confirmButtonText  The text of confirm button
* @return Swal.Promise<Swal.SweetAlertResult> (promise de sweetalert2)
*/
function confirmPopup(text, cancelButtonText, confirmButtonText): Swal.Promise<Swal.SweetAlertResult> {
    return Swal.fire({
        title: "",
        text: text,
        width: "460px",
        padding: "50px",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        buttonsStyling: false,
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        animation: false,
        cancelButtonClass: "btn btnNiri btnNiriGreyOutline",
        confirmButtonClass: "btn btnNiri btnNiriBlueOutline",
        customContainerClass: "contentConfirmPopup"
    });
}

/**
 * Fonction permettant de calculer la longueur de la chaine la plus longue parmi stringValues
 * @param stringValues tableau de chaines de caractères
 */
function getLongestStringLength(stringValues: Array<string>) : number {
    var longestLabel : number = 0;
    if (stringValues.length > 0) {
        longestLabel = stringValues.map(function (word) {
            return word.length;
        }).reduce(function (x, y) {
            return (x > y) ? x : y;
        });
    }
    return longestLabel;
}
